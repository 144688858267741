import React from "react"
import LessonTags from "../components/Lessons/LessonTags";
import LessonsNav from '../components/Lessons/Nav'

const LessonTagsPage = (props) => {
  if (typeof window === "undefined") return null

  return <>
    <LessonsNav {...props} />
    <LessonTags />
  </>
}

export default LessonTagsPage
