import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useTags, useTagUtils } from './index.state'
import { SketchPicker } from 'react-color'
import Button from '../../UI/Button'
import { FormTrash } from 'grommet-icons'
import { getLuminance } from 'polished'

const Wrapper = styled.div`

`

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  > * { margin-right: 12px; margin-bottom: 10px; }
`

const Tag = styled.div`
  padding: 5px 12px;
  background-color: ${({ color }: { color: string }) => color};
  border-radius: 30px;
  display: flex;
  align-items: center;
  color: ${props => getLuminance(props.color) > 0.5 ? '#000' : '#fff' };
`

const NewTagWrapper = styled.div`
  display: flex;
  align-items: center;
  > :first-child {
    margin-right: 10px;
    outline: none;
    border: none;
    border-radius: 6px;
    box-shadow: 0 2px 0 rgb(0 0 0 / 10%);
    padding: 3px 6px;
  }
  > :last-child {
    margin-left: 15px;
  }
  margin-bottom: 30px;
`

const ColorPicker = styled.div`
  position: relative;
  > :first-child {
    cursor: pointer;
    height: 26px;
    width: 26px;
    background-color: ${({ color }: { color: string }) => color};
    border-radius: 50%;
  }
  > :nth-child(3) {
    position: absolute;
  }
`

const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`

const NewTag = ({ onCreated, onUpdated, editing }: {
  onCreated?: (tag) => void,
  onUpdated?: (_id, payload) => void,
  editing?: { _id: string, value?: string, color?: string }
}) => {
  const [isDisplayed, setIsDisplayed] = useState(false)
  const [color, setColor] = useState(editing ? editing.color : '#000000')
  const { create, update } = useTagUtils()
  const inputRef = useRef()

  useEffect(() => {
    if (editing && editing.color) {
      setColor(editing.color)
    } else {
      setColor('#000000')
    }
  }, [editing])

  return <NewTagWrapper>
    <input ref={inputRef} type='text' defaultValue={editing && editing.value} />
    <ColorPicker color={color}>
      <div onClick={() => setIsDisplayed(d => !d)}/>
      { isDisplayed && <>
        <Cover onClick={() => setIsDisplayed(false)}/>
        <SketchPicker color={color} onChange={(color) => setColor(color.hex)} />
      </> }
    </ColorPicker>
    <Button onClick={async () => {
      if (editing) {
        // @ts-ignore
        let value = inputRef.current.value !== editing.value ? inputRef.current.value : undefined
        let newColor = color !== editing.color ? color : undefined
        let newTag = await update(editing._id, { value, color: newColor })
        onUpdated(editing._id, newTag)
      } else {
        // @ts-ignore
        let tag = await create(inputRef.current.value, color)
        onCreated(tag)
      }
      // @ts-ignore
    }}>{ editing ? 'Update' : 'Create' }</Button>
  </NewTagWrapper>
}


export default (props) => {
  const [loadingTags, fetchedTags] = useTags()
  const [tags, setTags] = useState([])
  const [editing, setEditing] = useState(null)

  useEffect(() => {
    if (fetchedTags) {
      // @ts-ignore
      setTags(fetchedTags)
    }
  }, [fetchedTags])

  const onTagAdded = (tag) => {
    setTags(tags => [tag, ...tags])
  }

  // const removeTag = async ({ _id }: { _id: string }) => {
  //   await remove(_id)
  //   onTagRemoved(_id)
  // }

  const onTagRemoved = (_id) => {
    setTags(tags => (tags || []).filter(t => t._id !== _id))
  }

  const onTagUpdated = (_id, payload) => {
    setTags(tags => (tags || []).map(t => t._id !== _id ? t : ({ ...t, ...payload })))
  } 

  if (loadingTags) return null
  return <Wrapper>
    <NewTag onCreated={onTagAdded} onUpdated={onTagUpdated} editing={editing} />
    <Tags>
    { /* @ts-ignore */ }
    { (tags || []).map(t => <Tag key={t._id} color={t.color}>
      <div onClick={() => setEditing(tag => tag && tag._id === t._id ? null : t)}>{t.value}</div>
      { /* <div onClick={() => removeTag(t)}>
        <FormTrash color='#000' />
    </div> */ }
    </Tag>) }
  </Tags>
  </Wrapper>
}