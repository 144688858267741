import { useApolloClient } from "@apollo/client"
import { useEffect, useState } from "react"
// @ts-ignore
import GET_TAGS from './getTags.graphql'
// @ts-ignore
import CREATE_TAG from './createTag.graphql'
// @ts-ignore
import UPDATE_TAG from './updateTag.graphql'
// @ts-ignore
import DELETE_TAG from './deleteTag.graphql'

export const useTags = () => {
  const client = useApolloClient()
  const [loading, setLoading] = useState(true)
  const [tags, setTags] = useState<any[]>([])

  useEffect(() => {
    const fetch = async () => {
      const { data } = await client.query({
        query: GET_TAGS,
        fetchPolicy: 'network-only'
      })
      setTags(data.lessonV2Tags)
      setLoading(false)
    }
    fetch()
  }, [])

  return [loading, tags]
}

export const useTagUtils = () => {
  const client = useApolloClient()

  const create = async (value: string, color: string) => {
    let { data } = await client.mutate({
      mutation: CREATE_TAG,
      variables: {
        input: { value, color }
      }
    })
    return data && data.createLessonV2Tag
  }

  const update = async (_id: string, input: { value?: string, color?: string }) => {
    let { data } = await client.mutate({
      mutation: UPDATE_TAG,
      variables: {
        _id,
        input
      }
    })
    return data && data.updateLessonV2Tag
  }

  const remove = async (_id) => {
    let { data } = await client.mutate({
      mutation: DELETE_TAG,
      variables: {
        _id
      }
    })
    return data && data.deleteLessonV2Tag
  }

  return { create, update, remove }
}